import apiApp from '@/api/config/apiApp'

function getNotificationsByResidential (
  residentialId,
  selectedDate
) {
  return apiApp.get(
  `/notifications/${
    residentialId
  }/admin?selectedDate=${
    selectedDate
  }`)
}

export {
  getNotificationsByResidential
}
