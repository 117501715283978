<template>
    <div class="justify-content-start h-100 view-container">
      <HeaderResidential />
      <div class="mt-4">
        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <h1 style="margin: 0;">{{$t('guard.notificationsFor')}} {{ formatDate(this.selectedDate) }}</h1>
          <div
            style="display: flex; justify-content: space-between;"
          >
            <div class="pt-1">
              <Button
                :text="$t('guard.chooseDate')"
                color="primary"
                class="d-inline"
                @clicked="openDateSelectModal"
              />
            </div>
            <div>
              <div class="ui icon input" style="width: 99%">
                <input type="text" v-model="filters.text" :placeholder="$t('search')">
                <i class="search icon"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3 mb-5" style="margin-left: 12px; margin-right: 13px">
          <div class="row">
            <table class="ui blue unstackable selectable celled table" style="cursor: default !important; color: #808080">
              <thead>
              <tr>
                <th>{{$t('guard.notificationType')}}</th>
                <th style="width: 15%" class="text-center">{{$t('building.apartment')}}</th>
                <th style="width: 25%" class="text-center">{{$t('guard.dateAndTime')}}</th>
              </tr>
              </thead>
              <tbody v-if="notificationsOrdered.length === 0">
                <tr>
                  <td colspan="8" class="text-center">
                    {{$t('guard.noNotifications')}}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr
                  v-for="notification in notificationsOrdered"
                  :key="notification"
                  >
                  <!-- NOTIFICATION TYPE -->
                  <td>
                    <h4 class="ui image header">
                      <div class="content">{{ notification.message }}</div>
                    </h4>
                  </td>
                  <!-- APARTMENT -->
                  <td class="text-center">
                    <div class="ui image header">
                      <div class="content">{{ notification.apartment }}</div>
                    </div>
                  </td>
                  <!-- DATE -->
                  <td style="text-align: center">
                    <div class="ui image header">
                      <div class="content">{{ formatDate(notification.createdAt) + ' - ' + notification.createdAt.toLocaleTimeString() }}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="notificationsExist" style="color: #808080;">
                <tr>
                  <th colspan="10" style="border: none">
                    <div class="ui right floated pagination menu">
                      <a class="icon item" style="color: #808080;" @click="prevPageOfNotification">
                        <i class="left chevron icon"></i>
                      </a>
                      <a
                        :class="classActivationStatusPage(numPagOfNotifications)"
                        class="item" style="color: #808080"
                        v-for="numPagOfNotifications in numberOfPagesOfNotificationsInSection"
                        :key="numPagOfNotifications"
                        @click="changePageOfNotification(numPagOfNotifications)"
                        >{{numPagOfNotifications}}</a>
                      <a class="icon item" @click="nextPageOfNotification">
                        <i class="right chevron icon" style="color: #808080;"></i>
                      </a>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <SelectSingleDateModal
      :show="isPickingDate"
      :currentDate="selectedDate"
      :label="'Consultar'"
      @close="closeDateSelectModal"
      @setDate="changeselectedDate"
    />
  </template>

<script>
import { mapGetters } from 'vuex'
import HeaderResidential from '../../components/ui/HeaderResidential'
// import helpers from '../../utils/helpers'
import { getNotificationsByResidential } from '../../api/notifications'
import Button from '../../components/form/Button'
import SelectSingleDateModal from '../../components/modals/SelectSingleDateModal'
import helpers from '../../utils/helpers'

export default {
  name: 'Notifications',
  components: {
    HeaderResidential,
    Button,
    SelectSingleDateModal
  },
  data () {
    return {
      showForm: false,
      isPickingDate: false,
      selectedDate: new Date(Date.now()),
      notifications: [],
      filters: {
        text: ''
      },
      maxNotificationsPerPageOfNotifications: 8,
      currentPageOfNotifications: 1
    }
  },
  watch: {
    selectedDate: function (val) {
      this.loadNotifications()
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),

    notificationsExist () {
      return this.notifications.length !== 0
    },

    numberOfNotificationsInSection () {
      return this.notifications.length
    },

    numberOfPagesOfNotificationsInSection () {
      if (this.numberOfNotificationsInSection <= this.maxNotificationsPerPageOfNotifications) {
        return 1
      }
      return Math.ceil(this.numberOfNotificationsInSection / this.maxNotificationsPerPageOfNotifications)
    },

    notificationsOrdered () {
      let notifications = this.notifications

      if (this.filters.text) {
        notifications = notifications.filter(notification => {
          const message = `${notification.message.toLowerCase()}`
          const apartment = `${notification.apartment.toLowerCase()}`
          return message.includes(this.filters.text.toLowerCase()) ||
            apartment.includes(this.filters.text.toLowerCase())
        })
      }
      notifications.sort((a, b) => a.createdAt - b.createdAt)

      const lowerIdx = this.maxNotificationsPerPageOfNotifications * (this.currentPageOfNotifications - 1)
      const upperIdx = this.currentPageOfNotifications < this.numberOfPagesOfNotificationsInSection ? lowerIdx + this.maxNotificationsPerPageOfNotifications : this.numberOfNotificationsInSection
      return notifications.slice(lowerIdx, upperIdx)
    }
  },
  created () {
    this.loadNotifications()
  },
  beforeUnmount () {
    this.notifications = []
    this.selectedDate = new Date(Date.now())
  },
  methods: {
    changeselectedDate (newDate) {
      this.selectedDate = newDate
    },

    openDateSelectModal () {
      this.isPickingDate = true
    },

    closeDateSelectModal () {
      this.isPickingDate = false
    },

    formatDate (date) {
      return helpers.formatDateDDMMYYServer(date)
    },

    classActivationStatusPage (numPagOfNotifications) {
      return {
        activePage: numPagOfNotifications === this.currentPageOfNotifications
      }
    },

    prevPageOfNotification () {
      if (this.currentPageOfNotifications !== 1) {
        this.currentPageOfNotifications -= 1
      }
    },

    nextPageOfNotification () {
      if (this.currentPageOfNotifications !== this.numberOfPagesOfNotificationsInSection) {
        this.currentPageOfNotifications += 1
      }
    },

    changePageOfNotification (numPagOfNotifications) {
      this.currentPageOfNotifications = numPagOfNotifications
    },

    async loadNotifications () {
      this.$store.dispatch('loading/setLoading', true)
      try {
        const initDate = `${
          this.selectedDate.getFullYear()
        }-${
          `${this.selectedDate.getMonth() + 1}`.padStart(2, '0')
        }-${
          `${this.selectedDate.getDate()}`.padStart(2, '0')
        }`
        const resp = await getNotificationsByResidential(
          this.activeResidential.residentialId,
          initDate
        )
        resp.data.forEach(notification => {
          notification.createdAt = notification.createdAt.replace('Z', '')
        })
        this.notifications = resp.data.map((noti) => {
          return {
            ...noti,
            createdAt: new Date(noti.createdAt)
          }
        })
      } catch (e) {
        console.log('error getting notifications')
        console.log(e)
      }
      this.$store.dispatch('loading/setLoading', false)
    }

  }
}
</script>

<style scoped>
  .ui.table {
    background: transparent;
  }

  .ui.table > thead > tr > th {
    color: #808080
  }

  .ui.image.header > .content {
    font-size: 14px;
    font-weight: normal;
  }

  .nonOnlineRow {
    color: #808080;
  }

  .nonOnlineRow:hover {
    color: #808080 !important;
  }

  .option-muted {
    opacity: .4
  }

  .activePage {
    background-color: #436f98 !important;
    color: white !important;
  }
</style>
