<template>
  <SuiModal basic v-model="dialog" style="overflow-y: hidden">
    <SuiModalContent style="overflow-y: hidden; height: 600px; display: flex !important; align-items: center">
        <div class="reservation_export_container">
            <div class="close_modal_x">
                <a @click="close()">
                    <i style="text-decoration:none !important; cursor: pointer;font-size: 25px"
                        class="times icon general-blue-color"
                    ></i>
                </a>
            </div>
            <SuiModalDescription>
                  <div class="description_container">
                    <form class="ui form fields">
                        <h1 style="align-self: flex-center; margin-left: -50px;">{{$t('chooseDate')}}</h1>
                        <!-- Select Date -->
                        <div class="range_date_container">
                            <div class="date">
                                <Datepicker
                                    name="selectDate"
                                    :placeholder="this.currentDate.toLocaleDateString()"
                                    style="
                                    --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
                                    --vdp-selected-bg-color: #436f98; text-align: center; max-width: 160px"
                                    inputFormat='dd/MM/yyyy'
                                    :locale="getLanguage()"
                                    :typeable="false"
                                    v-model="form.selectDate"
                                />
                            </div>
                        </div>
                    </form>

                    <!-- Export button -->
                    <div class="btn_container">
                      <!-- <div> -->
                        <Button
                            :appearance="classBtnObject"
                            :text=this.label
                            color="primary"
                            @clicked="confirmDate"
                        />
                      <!-- </div> -->
                    </div>
                  </div>
            </SuiModalDescription>
        </div>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import { SuiModal, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
import Datepicker from 'vue3-datepicker'
import { es, en } from 'date-fns/locale'
import i18n from '@/plugins/i18n'
import Button from '@/components/form/Button'
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators/dist/raw.esm'
import { mapActions } from 'vuex'

export default {
  name: 'SelectSingleDateModal',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: 'Consultar'
    },
    currentDate: {
      type: Date,
      required: false,
      default: new Date(Date.now())
    }
  },
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Datepicker,
    Button
  },
  emits: ['close', 'setDate'],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      form: {
        selectDate: this.currentDate
      }
    }
  },
  validations () {
    return {
      form: {
        selectDate: {
          required: helpers.withMessage(this.$t('chooseStartDate'), (selectDateValue) => Boolean(selectDateValue))
        }
      }
    }
  },
  computed: {
    dialog () {
      return this.show
    },
    es () {
      return es
    },
    en () {
      return es
    },
    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '7px 2.4rem !important'
      }
    },
    residential () {
      return this.$store.getters['residential/activeResidential']
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),

    confirmDate () {
      this.$emit('setDate', this.form.selectDate)
      this.$emit('close')
      this.v$.$reset()
    },
    getLanguage () {
      if (i18n.global.locale === 'en') {
        return en
      } else {
        return es
      }
    },
    close () {
      this.form.selectDate = null
      this.$emit('close')
      this.v$.$reset()
    }
  }
}
</script>

<style scoped>
    h1 {
      color: #2c3e50 !important;
      font-family: inherit !important;
      margin: 0;
      padding: 0;
      padding-left: 50px !important;
      max-width: inherit;
    }
    .reservation_export_container {
        background-color: #fff;
        width: 70%;
        height: 200px;
        border-radius: 20px;
    }
    .description_container{
        padding: 50px !important;
        padding-top: 0 !important;
        margin-top: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
        height: 100%;
        margin-top: -15px !important;
    }
    .range_date_container {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 15px;
    }
    .close_modal_x {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        padding-top: 15px;
    }
    .date {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center
    }
    .btn_container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
    }
</style>
